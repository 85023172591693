import React, { Component } from 'react';
import { Box, Card, Flex, Carousel, Heading, Subhead } from 'rebass';
import styled from 'styled-components';
import { colors, shadows } from '../theme';
import Img from 'gatsby-image';
import Icon from './Icon';
import Text from './Text';

const TemplateCard = styled(Card)`
  /* cursor: pointer; */
  text-align: left;
  line-height: 1.5;
  text-align: center;
  height: 300px;
  width: 475px;
  box-shadow: ${shadows.small};
  padding: 0px;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background-color: ${colors.white};
    box-shadow: ${shadows.medium};
  }

  &:focus {
    box-shadow: ${shadows.outline};
  }
`;

const MainImage = styled(Img)`
  width: 100%;
  height: 200px;
`;

const RightIcon = styled(Text)`
  position: relative;
  float: right;
  margin-top: -35px;
  margin-right: 10px;
  z-index: 1;
  top: 125px;
  border-radius: 5px;
  font-size: 1.5em;
  cursor: pointer;
`;

const LeftIcon = styled(Text)`
  position: relative;
  float: left;
  margin-top: -35px;
  margin-left: 10px;
  z-index: 1;
  top: 125px;
  border-radius: 5px;
  font-size: 1.5em;
  cursor: pointer;
`;

class TemplatePost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  rightArrow = e => {
    if (this.state.count < 2) {
      this.setState({
        count: this.state.count + 1,
      });
    } else {
      this.setState({
        count: 0,
      });
    }
  };

  leftArrow = e => {
    if (this.state.count > 0) {
      this.setState({
        count: this.state.count - 1,
      });
    } else if (this.state.count == 0) {
      this.setState({
        count: 2,
      });
    }
  };

  render() {
    return (
      <div>
        <TemplateCard>
          <div onClick={this.rightArrow}>
            <RightIcon>▶️</RightIcon>
          </div>
          <div onClick={this.leftArrow}>
            <LeftIcon>◀️</LeftIcon>
          </div>
          {/* <MainImage fluid={this.props.post.featuredImage.fluid} /> */}
          <Carousel index={this.state.count}>
            <Box>
              <MainImage fluid={this.props.post.screenshots[0].fluid} />
            </Box>
            <Box>
              <MainImage fluid={this.props.post.screenshots[1].fluid} />
            </Box>
            <Box>
              <MainImage fluid={this.props.post.screenshots[2].fluid} />
            </Box>
          </Carousel>

          <Subhead pt="15px">{this.props.post.title}</Subhead>
          <Text
            mt="-10px"
            dangerouslySetInnerHTML={{
              __html: this.props.post.description.childMarkdownRemark.html,
            }}
          />
        </TemplateCard>
      </div>
    );
  }
}

export default TemplatePost;
