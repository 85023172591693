import React, { Component } from 'react';
import { Flex, Box, Card, Heading } from 'rebass';
import TemplatePost from './TemplatePost';

class TemplateList extends Component {
  render() {
    return (
      <div>
        <Flex flexWrap="wrap" my={20}>
          {this.props.templates.map(post => (
            <Box key={post.node.index} w={1} px="15px" py="20px">
              <TemplatePost post={post.node} />
            </Box>
          ))}
        </Flex>
      </div>
    );
  }
}

export default TemplateList;
