import React, { Component } from 'react';
import Page from '../components/Page';
import Container from '../components/Container';
import { Box, Heading, Subhead, Button } from 'rebass';
import Checkout from '../components/Checkout';
import AboutBody from '../components/AboutBody';
import { graphql } from 'gatsby';
import CommunityBanner from '../components/CommunityBanner';
import TemplateList from '../components/TemplateList';
import Text from '../components/Text';
import TextLink from '../components/TextLink';
import styled from 'styled-components';
import { colors } from '../theme';

const ButtonLink = styled.a`
  padding: 12px;
  border-radius: 10px;
  background-color: ${colors.base};
  color: white;
`;

class Community extends Component {
  render() {
    return (
      <div>
        <Page>
          <CommunityBanner
            hearts={this.props.data.contentfulPages.hearts}
            title="Join the Community"
            type="product"
            members="0"
            date="1/2/2018"
            status="Coming Soon"
            templates="1"
          />
          <Container>
            <AboutBody content={this.props.data.contentfulPages} />
            {/* Pre-LAUNCH Hide checkout */}
            {/* <Checkout /> */}
            <Box pl="14%">
              <ButtonLink
                data-formkit-toggle="304ed3d90e"
                href="https://pages.convertkit.com/304ed3d90e/c61a263e4e"
              >
                {' '}
                😎 Coming soon. Sign up for early access
              </ButtonLink>
            </Box>
          </Container>
        </Page>
      </div>
    );
  }
}

export default Community;

export const query = graphql`
  query ComPagesQuery {
    contentfulPages(title: { eq: "Community" }) {
      id
      header
      bodyTitle
      hearts
      body {
        body
        childMarkdownRemark {
          id
          html
        }
      }
    }
    allContentfulTemplates {
      edges {
        node {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          featuredImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
          screenshots {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`;
