import React from 'react';
import styled from 'styled-components';
import {
  Flex,
  Box,
  Container,
  Row,
  Card,
  Banner,
  Heading,
  Subhead,
  Text,
  Divider,
  Embed,
  BackgroundImage,
} from 'rebass';
import { plural } from '../utils/helpers';
import Icon from './Icon';
import Link from 'gatsby-link';
import logOutboundLink from '../utils/log-outbound-link';
import Img from 'gatsby-image';
import moment from 'moment';

const HeartButton = styled(Icon)`
  :hover {
    cursor: pointer;
    color: #c1292e;
  }
`;

const NavIcon = styled(Icon)`
  position: relative;
  float: right;
  z-index: 1;
  right: 35px;
  margin-right: -35px;
  width: 25px;
  height: 25px;
  background: #c3bbe78a;
`;

const PostHeading = styled(Heading)`
  text-align: center;
`;

const ProductCard = styled(Card)`
  height: 100px;
  width: 150px;
  margin-right: 2vw;
`;

const BannerHome = styled(Banner)`
  min-height: 200px;
  height: 400px;
  max-height: 400px;
  text-decoration: none;
`;

const BannerWrap = styled.div`
  background-color: #0066ff;
`;

class PostBanner extends React.Component {
  render() {
    // PRODUCT BANNER

    return (
      <div>
        <BannerHome bg="#0066FF">
          <Flex flexWrap="wrap" justifyContent="center">
            <Box />

            <Box my="auto">
              <PostHeading is="h1" fontSize={[4, 4, 6]} color="white">
                {this.props.title}
              </PostHeading>
            </Box>
          </Flex>
        </BannerHome>
        <BannerWrap>
          <div
            style={{
              maxWidth: `850px`,
              marginRight: `auto`,
              marginLeft: `auto`,
            }}
          >
            <Flex bg="base" flexWrap="wrap" px={3} py={3}>
              <Box width={[1, 1 / 4]} p={1}>
                <Subhead m={1} fontSize={3} textAlign="center" color="gray1">
                  {' '}
                  💻 Status
                </Subhead>
                <Text textAlign="center" color="white">
                  {this.props.status}
                </Text>
              </Box>
              <Box width={[1, 1 / 4]} p={1}>
                <Link to="/templates" state={{ fromFeed: true }}>
                  <Subhead m={1} fontSize={3} textAlign="center" color="gray1">
                    {' '}
                    🛠 Starters
                  </Subhead>

                  <Text textAlign="center" color="white">
                    {this.props.templates}
                  </Text>
                </Link>
              </Box>
              <Box width={[1, 1 / 4]} p={1}>
                <Subhead m={1} fontSize={3} textAlign="center" color="gray1">
                  {' '}
                  🏠 Members
                </Subhead>
                <Text textAlign="center" color="white">
                  {this.props.members}
                </Text>
              </Box>
              <Box width={[1, 1 / 4]} p={1}>
                <a
                  href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    `Launching Myself Community is 🔥 - http://launchingmyself.com/community
                    `
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => logOutboundLink('tweet')}
                >
                  <Subhead m={1} fontSize={3} textAlign="center" color="gray1">
                    {' '}
                    🐦 Shares
                  </Subhead>
                  <Text textAlign="center" color="white">
                    {this.props.hearts}
                  </Text>
                </a>
              </Box>
            </Flex>
          </div>
        </BannerWrap>
      </div>
    );
  }
}

export default PostBanner;
